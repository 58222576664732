import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { FreshToken } from '../interfaces/user-details';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { EnvironmentVars } from '../interfaces/environment-vars';
import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import { camelCase } from 'lodash';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiHelperService {

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    @Inject('environment') private environment: EnvironmentVars,
    @Inject(PLATFORM_ID) private platformId: any,
    @Inject(DOCUMENT) private document: Document,
  ) {
    if (isPlatformServer(this.platformId)) {
      this.hostname = location.hostname;
      if (this.hostname.includes('local')) {
        this.serverPath = 'http://' + this.hostname + ':3000' + this.environment.pathapi;
      } else {
        this.serverPath = 'https://' + this.hostname + '/' + this.environment.pathapi;
      }
    }
  }

  private hostname: string = '';

  private serverPath: string = this.environment.pathapi;

  private pageItems = 100;
  private skipItems = 0;
  private currentPage = 0;

  resetLimits() {
    this.pageItems = 10;
    this.skipItems = 0;
  }

  paginator(page) {
    this.pageItems = page.pageSize;
    this.skipItems = page.pageIndex * page.pageSize;
    this.currentPage = page.pageIndex + 1;
  }

  public setServerPath(path) {
    this.serverPath = path;
  }

  public getServerPath() {
    return this.serverPath;
  }

  public getDataById(model, modelId) {

    // SSR Rendering: retrive data from html script which cashed by server side rendering
    let dataKey = camelCase(model + modelId);

    if (this.getCashedData(dataKey)) {
      return of(this.getCashedData(dataKey));
    }
    return this.http.get(`${this.serverPath}/${model}/${modelId}`).pipe(map(item => {
      this.setCashedData(dataKey, item);
      return item;
    }));
  }

  public getDataByIdAndFilter(model, modelId, filter: any) {

    // SSR Rendering: retrive data from html script which cashed by server side rendering
    let dataKey = camelCase(model + modelId + JSON.stringify(filter));

    if (this.getCashedData(dataKey)) {
      return of(this.getCashedData(dataKey));
    }

    return this.http.get(`${this.serverPath}/${model}/${modelId}`, {
      params: { filter: JSON.stringify(filter) }
    }).pipe(map(item => {
      this.setCashedData(dataKey, item);
      return item;
    }));;
  }

  public postData(model, data) {
    return this.http.post(`${this.serverPath}/${model.replace("/search", "")}`, data);
  }

  public deleteData(model, modelId) {
    return this.http.delete(`${this.serverPath}/${model.replace("/search", "")}/${modelId}`);
  }

  getUserDetails() {
    const userId = localStorage.getItem('userId');
    return this.http.get(this.serverPath + '/users/' + userId);
  }

  getCount(model, filter) {

    // SSR Rendering: retrive data from html script which cashed by server side rendering
    let dataKey = camelCase(model + JSON.stringify(filter));

    if (this.getCashedData(dataKey)) {
      return of(this.getCashedData(dataKey));
    }
    const params = filter.where ? { params: { where: JSON.stringify(filter.where) } } : { params: {} };
    // console.log(params);
    return this.http.get(`${this.serverPath}/${model}`, params);
  }

  public updateData(model, modelId, data) {
    return this.http.patch(`${this.serverPath}/${model.replace("/search", "")}/${modelId}`, data);
  }


  public prepareFilter(fields, searchValue, filterObj: any = {}) {
    // const filterObj: any = {};
    const filterValue = searchValue.trim();

    if (!filterObj.where) {
      filterObj.where = { or: [] };
    } else {
      filterObj.where = {
        and: [filterObj.where, { or: [] }]
      }
    }
    for (const ele of fields) {
      let orFilter = {};
      orFilter[ele] = { like: filterValue, options: 'i' };
      if (filterObj.where && filterObj.where.and) {
        filterObj.where.and[1].or.push(orFilter);
      } else {
        filterObj.where.or.push(orFilter);
      }
    }
    return filterObj;
  }

  public async getData(api: string, filter: any = null, isPagination: boolean = false): Promise<any[]> {

    // SSR Rendering: retrive data from html script which cashed by server side rendering
    let dataKey = camelCase(api + JSON.stringify(filter));

    if (this.getCashedData(dataKey)) {
      return this.getCashedData(dataKey);
    }

    return new Promise((resolve, reject) => {

      let params = filter ? { filter } : null;
      if (isPagination) {
        if (this.currentPage) {
          if (!params) { params = { filter: {} }; }
          params.filter.limit = this.pageItems;
          params.filter.skip = this.skipItems;
        }
      }
      if (params !== null) {
        params.filter = JSON.stringify(params.filter);
      }

      this.http.get(`${this.serverPath}/${api}`, { params })
        .subscribe((data: any[]) => {
          // SSR Rendering: store data into html script while rendering on server side
          this.setCashedData(dataKey, data);
          resolve(data);
        }, err => {
          reject(err);
        });
    });
  }

  getCashedData(dataKey: string) {
    if (isPlatformBrowser(this.platformId) && window[dataKey]) {
      return JSON.parse(decodeURIComponent(atob(window[dataKey])));
    }
  }

  setCashedData(dataKey: string, dataValue: any) {
    if (isPlatformServer(this.platformId)) {
      var head = this.document.getElementsByTagName('head')[0];
      var script = this.document.createElement('script');
      script.type = 'text/javascript';
      script.id = dataKey;
      let jsonStr = btoa(encodeURIComponent(JSON.stringify(dataValue)));
      script.text = `window.${dataKey} = "${jsonStr}";`;
      head.appendChild(script);
    }
  }

  openSnackBar(message: string, options?: MatSnackBarConfig, action?: string) {
    const defaultOptions: MatSnackBarConfig = {
      duration: 2000,
    };

    if (options) {
      Object.assign(defaultOptions, options);
    }

    if (!action) { action = ''; }

    this.snackBar.open(message, action, defaultOptions);
  }

  public flatten(obj) {
    const root = {};
    this.tree(root, obj, '');
    return root;
  }

  private tree(root, obj, index) {
    for (const key in obj) {
      if (!obj.hasOwnProperty(key)) { continue; }
      root[index + key] = obj[key];
      if (toString.call(obj[key]) === '[object Object]') {
        this.tree(root, obj[key], index + key + '.');
      }
      if (toString.call(obj[key]) === '[object Array]') {
        root[index + key + '.length'] = obj[key].length;
        this.tree(root, obj[key], index + key + '.');

      }
    }
  }

  public arrayToTree(array: any[], parentKey: string, idKey: string = 'id', childrenKey: string = 'Children') {
    let map: any = {}, node: any, roots: any[] = [], i: number;

    for (i = 0; i < array.length; i += 1) {
      map[array[i][idKey]] = i; // initialize the map
      array[i][childrenKey] = []; // initialize the children
    }

    for (i = 0; i < array.length; i += 1) {
      node = array[i];
      if (node[parentKey] !== "0") {
        // if you have dangling branches check that map[node[parentKey]] exists
        array[map[node[parentKey]]][childrenKey].push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  downloadCsvFromRows(filename: string, rows: any[]) {
    if (!rows || !rows.length) {
      return;
    }
    const separator = ',';
    const keys = Object.keys(rows[0]);
    const csvContent =
      keys.join(separator) +
      '\n' +
      rows.map(row => {
        return keys.map(k => {
          let cell = row[k] === null || row[k] === undefined ? '' : row[k];
          cell = cell instanceof Date
            ? cell.toLocaleString()
            : cell.toString().replace(/"/g, '""');
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(separator);
      }).join('\n');
    this.downloadCsv(csvContent, filename);
  }

  public downloadCsv(csvContent: string, filename: string = 'data.csv') {
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });

    const link = document.createElement('a');
    if (link.download !== undefined) {
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }

  }

  public updateMultiple(model, modelIds: string[], data) {
    const arrayID = modelIds.map(single => {
      return { id: single }
    })
    return this.http.patch(`${this.serverPath}/${model.replace("/search", "")}`, data, {
      params: {
        where: JSON.stringify({ or: arrayID })

      }
    });
  }

  public updateMultipleRecord(model, where: any, data) {
    return this.http.patch(`${this.serverPath}/${model.replace("/search", "")}`, data, {
      params: {
        where: JSON.stringify(where)

      }
    });
  }

  downloadCsvFromApi(api: string, fields: any, filter: any) {
    this.http.get(this.serverPath + '/exports/' + api, {
      headers: {
        "Accept": "text/csv"
      },
      params: {
        fieldObj: JSON.stringify({ fields: JSON.stringify(fields) }),
        filter: JSON.stringify(filter)
      },
      responseType: "text"
    }).subscribe((csv: any) => {
      var exportedFilenmae = api + '.csv' || 'export.csv';

      var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
  }


  public downloadZip(model, modelId) {
    return this.http.get(`${this.serverPath}/${model}/${modelId}`, {
      responseType: "text"
    });
  }


  public watchEventSource(model: string) {
    let url = this.serverPath + "/" + model;
    if (localStorage.getItem('token')) {
      url += `?authorization=Bearer ${localStorage.getItem('token')}`;
    }
    return new EventSource(url);
  }

  prepareTitleLink(title: string) {
    return title.trim().replace(/[ \/\+\.]/g, '-');
  }

  mapData(data: any, dataMapping: any) {
    return data.map((item: any) => {
      let origionalItem={
        ...item
      }
      item = this.flatten(item);
      item.origionalItem=origionalItem
      let keys = Object.keys(dataMapping)
      keys.forEach(key => {
        item[key] = item[dataMapping[key]]
        if (Array.isArray(dataMapping[key])) {
          item[key] = dataMapping[key].map(link => {
            let linkItem = {};
            Object.keys(link.fields).forEach((field) => {
              linkItem[field] = Object.keys(link.params).reduce((prev, curr) => typeof prev == 'object' ? prev = JSON.stringify(prev).replace(':' + curr, item[link.params[curr]]) : prev && prev.replace(':' + curr, item[link.params[curr]]), link.fields[field])
            })
            return linkItem;
          })
        } else {
          item[key] = item[dataMapping[key]];
        }
      })
      return item;
    })
  }
  replacePlaceholders(data: string, values: Record<string, any>): string {
    const item = this.flatten(values);
    return data.replace(/{{(.*?)}}/gi, (substring: string, key: string): string => {
      const trimmedKey = key.trim();
      const keys = trimmedKey
          .replace(/\[(\d+)]/g, '.$1') 
          .split('.'); 
      let value: any = item;
      for (const k of keys) {
        value = value ? value[k] : '';
      }
      return value || '';
    });
  }

}
