import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy, OnInit, } from '@angular/core';
import { sortBy } from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiHelperService } from './api-helper.service';
import { UserIdentityService } from './user-identity.service';
import { tap } from 'rxjs/operators';
import { FrontEndLoaderService } from 'projects/apna-lib/src/lib/services/front-end-loader.service';

@Injectable({
  providedIn: 'root'
})
export class CompanyIdentityService implements OnInit, OnDestroy {
  private _companyDetails: any = null;
  private _domainDetails: any = null;
  private _companyList: any[] = null;
  private _branchDetails: any = null;
  private _branchList: any[] = null;
  private _companyDetailsSubject = new BehaviorSubject(null);
  private _domainDetailsSubject = new BehaviorSubject(null);
  private _waInstanceList: any[] = null;
  private assetsInitialized: boolean = false;
  constructor(
    private apiHelper: ApiHelperService,
    private user: UserIdentityService,
    @Inject(DOCUMENT) private document: Document,
    public loader: FrontEndLoaderService,
  ) {
    this.getDomainCompany();
  }

  async ngOnInit() {
    this.user.isLoggedIn.subscribe(data => {
      this.getCompanies(this.user.userDetails);
      if (this._companyDetails?.id) {
        localStorage.setItem('companyId', this._companyDetails.id);
      }
    })
  }

  ngOnDestroy(): void {

  }

  async getCompanyDetails(companyId: string) {
    if (companyId) {
      try {
        return this.companyDetails = await this.apiHelper.getDataById('companies', companyId).toPromise();
      } catch (e) {
        console.log('getCompanyBy id err', e);
        return e;
      }
    }
    return;
  }

  async updatetCompanyDetails(companyDetails: any, updateData: any) {
    return this.apiHelper.updateData('companies', companyDetails.id, updateData).pipe(tap(res => {
      this.companyDetails = {
        ...companyDetails,
        ...updateData
      };
    })).toPromise();
  }

  async getCompanies(user: any) {
    let params = {
      where: {
        or: [{
          userId: user.id
        },
        {
          id: user.companyId
        }]
      }
    }
    let companies = await this.apiHelper.getData('companies', params);
    if (companies && companies.length == 1) {
      this.companyDetails = companies[0];
      this._companyList = [];
    } else if (companies?.length > 1) {
      this._companyList = companies.map(item => {
        item.value = item.id;
        return item;
      });
    }
    return this.companyList;
  }

  async getDomainCompany() {
    let domainDetails = this.loader.getData('domainDetails');
    if (domainDetails) {
      return this.initializeCompanyDetails(domainDetails);
    }
    let res: any;
    try {
      res = await this.apiHelper.getDataById("website-domains/search", location.hostname).toPromise();
      this.loader.setData('domainDetails', res);
    } catch (e) {
      console.log('company error', e);
    }
    if (!res?.Company) {
      this._companyDetailsSubject.next(false);
      return this.apiHelper.openSnackBar('Please add company');
    }
    return this.initializeCompanyDetails(res);
  }

  initializeCompanyDetails(res) {
    
    this._domainDetails = res;
    if (this._domainDetails.Theme) {
      this.loadThemeAssets(this._domainDetails);
      this.prepareWidgets(this._domainDetails.Theme);
    }
    this._domainDetailsSubject.next(this._domainDetails);

    let companyId = localStorage.getItem('companyId');
    if (companyId && companyId != res?.Company?.id) {
      return this.getCompanyDetails(companyId);
    }

    this._companyDetails = res?.Company;
    localStorage?.setItem('companyId', this._companyDetails?.id);
    this._companyDetailsSubject.next(this._companyDetails);
    return this._companyDetails;
  }

  async getBranchDetails(): Promise<any[]> {
    if (!this._companyDetails) return this._branchDetails = null;

    let params = {
      limit: 1,
      where: {
        companyId: this._companyDetails.id
      }
    }

    const res = await this.apiHelper.getData("branches", params);
    return this._branchDetails = res[0];

  }

  get companyDetails(): Observable<any> {
    return this._companyDetailsSubject.asObservable();
  }

  get domainDetails(): Observable<any> {
    return this._domainDetailsSubject.asObservable();
  }

  set companyDetails(company: any) {
    this._companyDetails = company;
    this._companyDetailsSubject.next(company);
    if (this._companyDetails?.id) {
      localStorage.setItem('companyId', this._companyDetails?.id);
    }
  }

  get companyList() {
    return this._companyList;
  }

  get branchList() {
    return this._branchList;
  }

  get waInstanceList() {
    return this._waInstanceList;
  }

  async getWaInstances() {
    let companyId = localStorage.getItem('companyId');
    let params = {
      where: {
        companyId: companyId
      }
    }
    let waInstances = await this.apiHelper.getData('crm-whatsapps', params);

    if (waInstances && waInstances.length) {
      // this.companyDetails = companies[0];
      this._waInstanceList = waInstances[0];
    }
    return this.waInstanceList;
  }

  
  loadExternalScript(scriptPath: string) {
    let script = scriptPath;
    let scriptEl = this.document.createElement('script');
    scriptEl.src = script;
    scriptEl.type = 'module';
    this.document.body.appendChild(scriptEl);
  }

  loadExternalStyle(stylePath: string) {
    let style = stylePath;
    let styleEl = this.document.createElement('link');
    styleEl.href = style;
    styleEl.rel = 'stylesheet';
    this.document.head.appendChild(styleEl);
  }

  loadStyle(style: string) {
    let styleEl = this.document.createElement('style');
    styleEl.innerHTML = style;
    this.document.head.appendChild(styleEl);
  }

  loadScript(script: string) {
    let scriptEl = this.document.createElement('script');
    scriptEl.innerHTML = script;
    this.document.head.appendChild(scriptEl);
  }

  loadBrandColors(brandColors: any) {
    let styleEl = this.document.createElement('style');
    const hexToRgb = hex => hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (m, r, g, b) => '#' + r + r + g + g + b + b).substring(1).match(/.{2}/g).map(x => parseInt(x, 16)).join(', ');
    styleEl.textContent = `
      :root {
        /* Brand Colors */

        --bs-primary: ${brandColors.primary};

        --bs-secondary: ${brandColors.secondary};

        --bs-purple: ${brandColors.tertiary};

        --bs-indigo-rgb: ${hexToRgb(brandColors.quaternary)};

        --bs-primary-rgb: ${hexToRgb(brandColors.primary)};

        --bs-secondary-rgb: ${hexToRgb(brandColors.secondary)};

        --bs-purple-rgb: ${hexToRgb(brandColors.tertiary)};

        --bs-indigo-rgb: ${hexToRgb(brandColors.quaternary)};

        /* Theme Colors */

        --bs-success: ${brandColors.success};

        --bs-info: ${brandColors.info};

        --bs-warning: ${brandColors.warning};

        --bs-danger: ${brandColors.danger};

        --bs-light: ${brandColors.light};

        --bs-dark: ${brandColors.dark};

        --bs-success-rgb: ${hexToRgb(brandColors.success)};

        --bs-info-rgb: ${hexToRgb(brandColors.info)};

        --bs-warning-rgb: ${hexToRgb(brandColors.warning)};

        --bs-danger-rgb: ${hexToRgb(brandColors.danger)};

        --bs-light-rgb: ${hexToRgb(brandColors.light)};

        --bs-dark-rgb: ${hexToRgb(brandColors.dark)};
      }
    `
    this.document.head.appendChild(styleEl);
  }

  async loadThemeAssets(domainDetails) {
    if (this.assetsInitialized) return console.log('Assets already initialized...');
    if (domainDetails.Theme?.scripts?.length) {
      domainDetails.Theme?.scripts?.forEach(item => {
        this.loadExternalScript(item);
      })
    }
    if (domainDetails.Theme?.styles?.length) {
      domainDetails.Theme?.styles?.forEach(item => {
        this.loadExternalStyle(item);
      })
    }
    /* if (domainDetails.Theme?.brandColors) {
      this.loadBrandColors(domainDetails.Theme?.brandColors);
    } */
    if (domainDetails.Theme?.style) {
      this.loadStyle(domainDetails.Theme?.style);
    }
    if (domainDetails.Theme?.script) {
      this.loadScript(domainDetails.Theme?.script);
    }

    this.assetsInitialized = true;
    
  }

  prepareWidgets(data: any, reset: boolean = false) {
    if (!data) return;
    if (!data?.WidgetsObject || reset === true) {
      data.WidgetsObject = {
        HeaderTop: [],
        HeaderBottom: [],
        FooterTop: [],
        FooterBottom: [],
        FooterBottomNavbar: [],
        WidgetLeft: [],
        WidgetRight: [],
        WidgetTop: [],
        WidgetBottom: [],
      }
    }
    data.widgets = sortBy(data.widgets, 'order');
    data.widgets?.forEach(element => {
      if (!element.Widget) return;
      element.Widget.heading = element.heading;
      element.Widget.subHeading = element.subHeading;
      element.Widget.description = element.description;
      element.Widget.className = element.className;
      element.Widget.order = element.order;
      element.Widget.show = element.show;
      if (element.show) {
        if (!data.WidgetsObject[element.placement]) data.WidgetsObject[element.placement] = [];
        data.WidgetsObject[element.placement].push(element.Widget);
      }
    });
    return data;
  }

}
